import React from "react";
import { Routes, Route } from "react-router-dom";
import { Homepage } from "../Pages/HomePage";
import { EmployeeLayouts } from "../Layouts/EmployeeLayouts";
import { EmployeeDash } from "../Employee/pages/EmployeeDash";
import { ViewAttendance } from "../Employee/pages/ViewAttendance";
import { ApplyLeave } from "../Employee/pages/applyLeave/ApplyLeave";
import { Complaint } from "../Employee/pages/Cpmplaint/Complaint";
import { ProfileData } from "../Employee/pages/ProfileData";
import { SalarySlip } from "../Employee/pages/SalarySlip";
import { LeaveForm } from "../Employee/pages/applyLeave/LeaveForm";
import { Login } from "../components/Login";
import { ProtectedRoute } from "./ProtectedRoute";
import { ViewLeaveRecord } from "../Employee/pages/applyLeave/ViewLeaveR";
import { ComplaintForm } from "../Employee/pages/Cpmplaint/ComplaintForm";
import { ViewComplaint } from "../Employee/pages/Cpmplaint/ViewComplaint";
import { InvoicePdf } from "../utils/docConverter";
import { HolidayList } from "../Employee/pages/Holiday/HolidayList";
import HolidayDetails from "../Employee/pages/Holiday/HolidayDetails";
import { UploadProfileImg } from "../Employee/components/UploadProfileImg";

export const UserRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />

      <Route path="/login" element={<Login />} />

      <Route
        path="/employee"
        element={<ProtectedRoute components={<EmployeeLayouts />} />}
      >
        <Route index element={<EmployeeDash />} />
        <Route path="dashboard" element={<EmployeeDash />} />
        <Route path="upload-profile_image" element={<UploadProfileImg />} />
        <Route path="apply_leave" element={<ApplyLeave />} />
        <Route path="complaint" element={<Complaint />} />
        <Route path="profile_data" element={<ProfileData />} />
        <Route path="salary_slip" element={<SalarySlip />} />
        <Route path="view_attendance" element={<ViewAttendance />} />
        <Route path="leave_form" element={<LeaveForm />} />
        <Route path="view-leave-records" element={<ViewLeaveRecord />} />
        <Route path="view-leave-records" element={<ViewLeaveRecord />} />
        <Route path="complaint-form" element={<ComplaintForm />} />
        <Route path="view-complaint" element={<ViewComplaint />} />
        <Route path="salary-view" element={<InvoicePdf />} />
        <Route path="holiday" element={<HolidayList />} />
        <Route path="holiday-details" element={<HolidayDetails />} />
      </Route>
    </Routes>
  );
};
