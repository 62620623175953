import React from 'react';

import { BrowserRouter, Routes,Route } from 'react-router-dom';
import { UserRoute } from './Routing/UserRoute';
import { AdminLayouts } from './Layouts/AdminLayouts';
import { Sidebar } from './Admin/components/Sidebar';
import { AdminRoute } from './Routing/AdminRout';
import { AdminDash } from './Admin/AdminPages/AdminDash';
import { EmployeeLayouts } from './Layouts/EmployeeLayouts';
import store from './Redux/store';
import { Provider } from 'react-redux';

export const App=()=>{
    return(
        <Provider store={store}>
            <BrowserRouter>
                <UserRoute/>
                <AdminRoute/>
            </BrowserRouter>
        </Provider>
    );
};