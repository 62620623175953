import React from "react";
import { SocialHeader,ContactHeader,Navigation} from "../components/headers";
import { Hero } from '../components/hero';
import { About } from '../components/aboutus';
import { Services,CompanySkills} from '../components/services';
import { TeamMembers,FAQ} from '../components/teams';
import { Footer } from '../components/footer';

export const Homepage = () =>{
    return(
        <>
         <SocialHeader/>
            <ContactHeader/>
            <Navigation/>
            <Hero/>
            <About/>
            <Services/>
            <TeamMembers/>
            <FAQ/>
            <CompanySkills/>
            {/* <CustomerFeedback/> */}
            {/* <ClientsList/> */}
            <Footer/>
        </>
    )
}