import React from "react";
import profileImg from "../../assets/data/pro1.jpg"

export const AdminProfile = () =>{
    return(
        <>
           <div className="adminProfile mb-5 max-sm:p-3">
           <div className="text-center text-orange-600 text-2xl font-semibold">
            <h1>GET YOUR PROJECT READY PRIVATE LIMITED</h1>
         </div>
         {/* <div className="flex px-2 gap-5 mt-5">
        <img className="w-12 h-12 rounded-full" src={profileImg} alt="" />
       <div>
       <h1 className="text-2xl text-blue-900 font-semibold mb-2" >Welcome Back</h1>
       </div>
    </div> */}

           </div>
        </>
      

    )
}